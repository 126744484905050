::selection {
    color: #fff;
    background: #5e0c0c
}

.auth-main {
    background: #5e0c0c
}

.sidebar-nav .metismenu>li.active>a,
.sidebar-nav .metismenu>li a:hover {
    background: #5e0c0c;
    color: #fff
}

.sidebar-nav .metismenu>li ul .active a {
    color: #5e0c0c
}

.sidebar-nav .metismenu ul a:hover::before {
    background: #5e0c0c
}

.form-control:focus {
    color: #5e0c0c
}

.fancy-checkbox input[type="checkbox"]:checked+span:before {
    color: #5e0c0c;
    border-color: #5e0c0c
}

.chat-widget li.right .chat-info {
    background: #5e0c0c;
    color: #fff
}

.chat-widget li.right .chat-info:before {
    border-left: 10px solid #5e0c0c
}

.wizard>.steps .current a {
    background: #5e0c0c
}

.wizard>.steps .done a {
    background: #6f7b7d
}

.pagination>li>a {
    color: #5e0c0c
}

.pagination>li>a:hover {
    background: #5e0c0c;
    border-color: #5e0c0c;
    color: #fff
}

.pagination>li>span {
    color: #5e0c0c
}

.page-item.active .page-link {
    background-color: #5e0c0c;
    border-color: #5e0c0c
}

.switch input:checked+.slider {
    background-color: #5e0c0c
}

.switch input:focus+.slider {
    box-shadow: 0 0 1px #5e0c0c
}

.navbar-fixed-top #navbar-search .form-control:focus,
.navbar-fixed-top #navbar-search .form-control:active {
    background: #fff;
    color: #5e0c0c
}

.navbar-fixed-top {
    border-color: #5e0c0c
}

.sidebar_icon {
    background: #5e0c0c
}

#left-sidebar {
    border-color: #5e0c0c
}