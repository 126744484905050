.login_body {
    min-height: 100vh;
    font-weight: 400;
    font-family: sans-serif;
   background: #5e0c0c;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom right,#5e0c0c, #880101);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom right,#5e0c0c,#880101); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
#main-content{
    min-height: 100vh;
    background-color:white !important;

}

body .login_body{
    overflow: hidden;
}