.subject-row:hover{
    background-color: lightgrey;

}

h1, h2, h3, h4, h5, h6{
    color: black!important;
}
.text-black{
    color: black;
}
.sidebar_list{
    z-index: 999999!important;
}
#Footer{
    position: absolute;
    bottom: 0;
    margin: auto;
    left: 30%;
    width: 100px;
}
@keyframes ldio-9smo7gkmuaf {
    0% { transform: rotate(0) }
    100% { transform: rotate(360deg) }
}
.ldio-9smo7gkmuaf div { box-sizing: border-box!important }
.ldio-9smo7gkmuaf > div {
    position: absolute;
    width: 83px;
    height: 83px;
    top: 8.5px;
    left: 8.5px;
    border-radius: 50%;
    border: 11px solid #000;
    border-color: #bd0505 transparent #bd0505 transparent;
    animation: ldio-9smo7gkmuaf 2.564102564102564s linear infinite;
}

.ldio-9smo7gkmuaf > div:nth-child(2), .ldio-9smo7gkmuaf > div:nth-child(4) {
    width: 59px;
    height: 59px;
    top: 20.5px;
    left: 20.5px;
    animation: ldio-9smo7gkmuaf 2.564102564102564s linear infinite reverse;
}
.ldio-9smo7gkmuaf > div:nth-child(2) {
    border-color: transparent #d50101 transparent #d50101
}
.ldio-9smo7gkmuaf > div:nth-child(3) { border-color: transparent }
.ldio-9smo7gkmuaf > div:nth-child(3) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
}
.ldio-9smo7gkmuaf > div:nth-child(3) div:before, .ldio-9smo7gkmuaf > div:nth-child(3) div:after {
    content: "";
    display: block;
    position: absolute;
    width: 11px;
    height: 11px;
    top: -11px;
    left: 25px;
    background: #bd0505;
    border-radius: 0%;
    box-shadow: 0 72px 0 0 #bd0505;
}
.ldio-9smo7gkmuaf > div:nth-child(3) div:after {
    left: -11px;
    top: 25px;
    box-shadow: 72px 0 0 0 #bd0505;
}

.ldio-9smo7gkmuaf > div:nth-child(4) { border-color: transparent; }
.ldio-9smo7gkmuaf > div:nth-child(4) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
}
.ldio-9smo7gkmuaf > div:nth-child(4) div:before, .ldio-9smo7gkmuaf > div:nth-child(4) div:after {
    content: "";
    display: block;
    position: absolute;
    width: 11px;
    height: 11px;
    top: -11px;
    left: 13px;
    background: #d50101;
    border-radius: 0%;
    box-shadow: 0 48px 0 0 #d50101;
}
.ldio-9smo7gkmuaf > div:nth-child(4) div:after {
    left: -11px;
    top: 13px;
    box-shadow: 48px 0 0 0 #d50101;
}
.loadingio-spinner-double-ring-fupwv6jvt9e {
    width: 28px;
    height: 28px;
    display: inline-block;
    overflow: hidden;
    background: none;
}
.ldio-9smo7gkmuaf {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.28);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.ldio-9smo7gkmuaf div { box-sizing: content-box; }
/* generated by https://loading.io/ */


.animation {
    overflow: hidden;
  }

.timer{
    opacity: 0.8;
   
    text-transform: capitalize!important;
    font-size: smaller!important;
    color:#858c97!important;

}
 .popup {
  padding: 4px!important;

 }

 .alert-danger{
    border-color: rgb(114, 28, 36)!important;
 }

  input[type="text"]:disabled,input[type="number"]:disabled{

        background-color: transparent!important;
        color: black!important;
        border: 0px!important;
    }
div.mdb-datatable label, .mdb-datatable-info{
    color:#212529!important;

}
.table-topic{
    background-color: #b3c98c!important;
}
.btn-info{

    background-color: #5e0c0c!important;
    border: #5e0c0c!important;
}
.highlight{
    background-color: transparent;
}
.highlight pre code {
    font-size: inherit;
    color: #212529;
}
.nt {
    color: #2f6f9f;
}
.na {
    color: #4f9fcf;
}
.s {
    color: #d44950;
}
pre.prettyprint {
    background-color: #eee;
    border: 0px;
    margin: 0;        
    padding: 20px;
    text-align: left;
}

.atv,
.str {
    color: #05AE0E;
}

.tag,
.pln,
.kwd {
    color: #3472F7;
}

.atn {
    color: #2C93FF;
}

.pln {
    color: #a5a5a5;
}

.com {
    color: #999;
}



.forum-post-container .media {
    margin: 10px 10px 10px 10px;
    padding: 20px 10px 20px 10px;
    border-bottom: 1px solid #f1f1f1;
}
.forum-avatar {
    float: left;
    margin-right: 20px;
    text-align: center;
    width: 110px;
}
.forum-avatar .img-circle {
    height: 48px;
    width: 48px;
}
.author-info {
    color: #676a6c;
    font-size: 11px;
    margin-top: 5px;
    text-align: center;
}
.forum-post-info {
    padding: 9px 12px 6px 12px;
    background: #f9f9f9;
    border: 1px solid #f1f1f1;
}
.media-body > .media {
    background: #f9f9f9;
    border-radius: 3px;
    border: 1px solid #f1f1f1;
}
.forum-post-container .media-body .photos {
    margin: 10px 0;
}
.forum-photo {
    max-width: 140px;
    border-radius: 3px;
}
.media-body > .media .forum-avatar {
    width: 70px;
    margin-right: 10px;
}
.media-body > .media .forum-avatar .img-circle {
    height: 38px;
    width: 38px;
}
.mid-icon {
    font-size: 66px;
}
.forum-item {
    margin: 10px 0;
    padding: 10px 0 20px;
    border-bottom: 1px solid #f1f1f1;
}
.views-number {
    font-size: 24px;
    line-height: 18px;
    font-weight: 400;
}
.forum-container,
.forum-post-container {
    padding: 30px !important;
}
.forum-item small {
    color: #999;
}
.forum-item .forum-sub-title {
    color: #999;
    margin-left: 50px;
}
.forum-title {
    margin: 15px 0 15px 0;
}
.forum-info {
    text-align: center;
}
.forum-desc {
    color: #999;
}
.forum-icon {
    float: left;
    width: 30px;
    margin-right: 20px;
    text-align: center;
}
.forum-item-title {
    color: inherit;
    display: block;
    font-size: 18px;
    font-weight: 600;

}
a.forum-item-title:hover {
    color: inherit;
}
.forum-icon .fa {
    font-size: 30px;
    margin-top: 8px;
    color: #9b9b9b;
}
.forum-item.active .fa {
    color: #1ab394;
}
.forum-item.active a.forum-item-title {
    color: #1ab394;
}
